<template>
  <div class="floating-support-chat" v-if="showChat">
    <!-- Chat Button -->
    <div v-if="!isExpanded" class="chat-button" @click="toggleExpand">
      <v-btn
        fab
        color="primary"
        elevation="8"
        class="chat-fab"
      >
        <v-icon>mdi-message-text</v-icon>
      </v-btn>
    </div>
    
    <!-- Chat Card -->
    <v-card
      v-else
      class="floating-chat-card chat-expanded"
      elevation="8"
      rounded="lg"
    >
      <!-- Chat Header -->
      <v-card-title 
        class="chat-header primary white--text" 
      >
        <v-icon left color="white">mdi-headset</v-icon>
        <span>Contact support</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="toggleExpand">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <!-- Chat Content -->
      <v-card-text v-if="isExpanded" class="chat-content white">
        <!-- Support Form -->
        <template v-if="!ticket_sent">
          <v-form ref="support_form" @submit.prevent="submit_support_ticket">
            <p class="text-body-1 mb-4">
              Reach out to us and we will respond by email within a few hours.
            </p>
            
            <v-text-field
              type="email"
              outlined
              rounded
              v-model="email"
              :rules="rules.user.email"
              label="E-mail"
              prepend-inner-icon="mdi-email"
              dense
              class="mb-3"
            />
            
            <v-textarea
              outlined
              rounded
              v-model="question"
              :rules="[v => v.length > 10 || 'Please provide more details']"
              rows="4"
              label="How can we help you?"
              placeholder="Describe your issue or question in detail..."
              prepend-inner-icon="mdi-help-circle"
              dense
              class="mb-4"
            />
            
            <v-btn
              color="primary"
              type="submit"
              rounded
              block
              elevation="2"
              :loading="submitting"
              class="submit-btn"
            >
              <v-icon left>mdi-send</v-icon>
              Send message
            </v-btn>
          </v-form>
        </template>
        
        <!-- Success Message -->
        <template v-else>
          <div class="text-center py-6">
            <v-icon color="success" size="64" class="mb-4">mdi-check-circle</v-icon>
            <h3 class="text-h5 font-weight-medium mb-3">Thanks for contacting us</h3>
            <p class="text-body-1 mb-6">
              We will reach out to you by email typically within a few hours!
            </p>
            <v-btn
              text
              color="primary"
              @click="resetForm"
            >
              <v-icon left>mdi-refresh</v-icon>
              Send another message
            </v-btn>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import rules from '@/utils/rules'

export default {
  name: 'FloatingSupportChat',
  data() {
    return {
      question: '',
      email: '',
      rules,
      ticket_sent: false,
      isExpanded: false,
      submitting: false
    }
  },
  computed: {
    showChat() {
      return !this.$route.path.includes('/fulfill') && !this.$route.path.includes('/warehouse') && !this.$route.path.includes('/shipments')
    }
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
      if (this.isExpanded && this.$store.state.user && this.$store.state.user.userProfile) {
        this.email = this.$store.state.user.userProfile.email || '';
      }
    },
    async submit_support_ticket() {
      if (!this.$refs.support_form.validate()) return;
      
      this.submitting = true;
      const { email, question } = this;
      
      try {
        await this.$store.dispatch('app/submit_support_ticket', { email, question });
        this.ticket_sent = true;
      } catch (error) {
        console.error('Error submitting support ticket:', error);
      } finally {
        this.submitting = false;
      }
    },
    resetForm() {
      this.ticket_sent = false;
      this.question = '';
    }
  },
  mounted() {
    // Initialize email from user profile if available
    if (this.$store.state.user && this.$store.state.user.userProfile) {
      this.email = this.$store.state.user.userProfile.email || '';
    }
  }
}
</script>

<style scoped>
.floating-support-chat {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 9999;
}

.chat-button {
  display: flex;
  justify-content: flex-end;
}

.chat-fab {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.chat-fab:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
}

.floating-chat-card {
  width: 350px;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  animation: slideUp 0.3s ease-out;
}

.chat-header {
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.chat-content {
  padding: 20px;
  max-height: 450px;
  overflow-y: auto;
}

.submit-btn {
  transition: transform 0.2s ease;
}

.submit-btn:hover {
  transform: translateY(-2px);
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 600px) {
  .floating-chat-card {
    width: 320px;
  }
  
  .floating-support-chat {
    bottom: 16px;
    right: 16px;
  }
}
</style>
